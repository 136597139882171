"use client";
import Image from "next/image";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useDispatchIncreaseActiveLineCards,
  useDispatchSetActiveLineCardValues,
  useDispatchShowLoader,
  useSelectActiveLineCards,
  useSelectAuthUser,
  useSelectCsrfToken,
  useSelectShowFavouritesNumbers,
  useDispatchSetShowFavouritesNumbers,
  useDispatchShowFavouritesModal,
  useDispatchAddFavouritesModal,
  useDispatchAddFavouritesNumbers,
  useDispatchLogout,
  useSelectGameGroups,
} from "@/hooks";
import {
  checkNumberAndWrap,
  sortLineCardValues,
} from "@/utils/functions.client";

export default function LineCard({
  cardTitle = "Line",
  lineNumber = 1,
  selectedNumbers = [],
  isActive = false,
  disableLine = "",
  showFavouritesImage = "",
  addFavouritesImage = "",
  randomImage = "",
  clearImage = "",
  totalCombination = 4,
  totalLineDigits = 40,
  fromCart = false,
  activateCard = function () {},
  randomizeCard = function () {},
  clearCard = function () {},
  selectCardNumber = function () {},
  disableShowFavourites = false,
  disableAddFavourites = false,
  disableRandomButton = false,
  disableClearButton = false,
  disableIncreaseButton = false,
}) {
  const activeLineCards = useSelectActiveLineCards();
  const authUser = useSelectAuthUser();
  const csrfToken = useSelectCsrfToken();
  const favouriteNumbers = useSelectShowFavouritesNumbers();
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchIncreaseActiveLineCards = useDispatchIncreaseActiveLineCards();
  const dispatchSetActiveLineCardValues = useDispatchSetActiveLineCardValues();
  const dispatchSetShowFavouritesNumbers =
    useDispatchSetShowFavouritesNumbers();
  const dispatchShowFavouritesModal = useDispatchShowFavouritesModal();
  const dispatchAddFavouritesModal = useDispatchAddFavouritesModal();
  const dispatchAddFavouritesNumbers = useDispatchAddFavouritesNumbers();
  const dispatchLogout = useDispatchLogout();
  const gameGroups = useSelectGameGroups();
  const gameGroupId = gameGroups?.[0]?.gameGroupID || 0;
  const lines = [];
  const dispatchSelection = (newSelectedNumbers) => {
    // Dispatch action to update the state with the new list
    dispatchSetActiveLineCardValues({
      index: lineNumber - 1,
      values: sortLineCardValues(newSelectedNumbers),
      totalCombination,
    });
  };
  for (let i = 1; i <= totalLineDigits; i++) {
    lines.push(
      <div
        key={i}
        className={
          "betNum bet_num ffv" +
          (selectedNumbers?.includes(i) ? " betNumMarked" : "")
        }
        onClick={() => {
          // Ensure the click action should proceed
          if (!fromCart && !disableRandomButton) {
            if (selectedNumbers?.includes(i)) {
              // Number is already selected, so remove it
              const newSelectedNumbers = selectedNumbers.filter(
                (num) => num !== i
              );
              dispatchSelection(newSelectedNumbers);
            } else {
              // Number is not selected, so add it
              if (selectedNumbers.length < totalCombination) {
                const newSelectedNumbers = [...selectedNumbers, i];
                dispatchSelection(newSelectedNumbers);
              }
            }
          } else {
            // Handle the case where the number is already selected
            selectCardNumber(i);
          }
        }}
      >
        <span className="digits-iq">{i}</span>
      </div>
    );
  }
  const randomSelectNumbers = () => {
    const newSelectedNumbers = new Set();
    while (newSelectedNumbers.size < totalCombination) {
      const randomNumber = Math.floor(Math.random() * totalLineDigits) + 1;
      newSelectedNumbers.add(randomNumber);
    }
    dispatchSetActiveLineCardValues({
      index: lineNumber - 1,
      values: sortLineCardValues([...newSelectedNumbers]),
      totalCombination,
    });
  };
  const getFavouritesList = async () => {
    dispatchShowLoader(true);
    if (csrfToken && favouriteNumbers?.length < 1) {
      const response = await sendInternalPostRequest({
        endpoint: "/api/favourite-numbers/list",
        data: {
          gameGroupId,
        },
        csrfToken,
      });

      if (response?.success && Array.isArray(response.favouriteNumbers)) {
        await dispatchSetShowFavouritesNumbers([...response.favouriteNumbers]);
      } else if (
        !!response?.message &&
        response.message.toLowerCase() === "authentication failed!"
      ) {
        dispatchLogout();
      }
    }
    dispatchShowLoader(false);
  };

  return (
    <>
      <div
        className={
          "number-picker__picker" +
          (!isActive ? " disabled" : "") +
          (selectedNumbers?.length === totalCombination ? " pickerFull" : "")
        }
      >
        <div
          className="number-picker__picker--disabled"
          onClick={() => {
            if (!disableIncreaseButton) {
              if (!fromCart) {
                const total = lineNumber - activeLineCards.length;
                if (total > 0) {
                  for (let i = 0; i < total; i++) {
                    dispatchIncreaseActiveLineCards();
                  }
                }
              } else {
                activateCard();
              }
            }
          }}
        >
          <p>{parser(checkNumberAndWrap(disableLine, "text") || "")}</p>
        </div>
        <div className="number-picker__picker--name">
          <p className="ffv">{cardTitle || ""}</p>
          <span className="ffv number-picker__picker--number digits-iq">
            {lineNumber || -1}
          </span>
          <div
            className="number-picker__picker--bottom"
            style={{
              width: "100%",
              display: "flex",
              verticalAlign: "center",
              background: "transparent",
            }}
          >
            {authUser?.profile?.dateOfBirth ? (
              <>
                <a
                  className="number-picker__picker--favourite-numbers"
                  href="#modal-fav-numbers"
                  id="favouriteNumbers"
                  title="Choose from Favourites"
                  onClick={async (e) => {
                    e.preventDefault();
                    await getFavouritesList();
                    dispatchShowFavouritesModal({ value: true, lineNumber });
                  }}
                  style={{ backgroundColor: "#fff" }}
                >
                  <Image
                    src={showFavouritesImage}
                    alt="Show Favourites"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                    width={18}
                    height={18}
                    loading="lazy"
                  />
                </a>

                {selectedNumbers?.length === totalCombination ? (
                  <a
                    className="number-picker__picker--favourites"
                    href="#modal-addfav"
                    id="addFavourites"
                    title="Add to Favourites"
                    onClick={async (e) => {
                      e.preventDefault();
                      await getFavouritesList();
                      dispatchAddFavouritesNumbers(selectedNumbers);
                      dispatchAddFavouritesModal(true);
                    }}
                  >
                    <Image
                      src={addFavouritesImage}
                      alt="Add Favourites"
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      width={18}
                      height={18}
                      loading="lazy"
                    />
                  </a>
                ) : (
                  ""
                )}
                <span
                  className="number-picker__picker--divider"
                  style={{
                    backgroundColor: "#fff",
                    margin: "3px 10px 0",
                  }}
                ></span>
              </>
            ) : (
              ""
            )}
            <div
              className="number-picker__picker--randomize betRandom linecard-icon d-flex align-items-center justify-content-center"
              title="Randomize"
              onClick={() => {
                if (!fromCart) {
                  for (let i = 0; i < 10; i++) {
                    setTimeout(randomSelectNumbers, i * 50);
                  }
                } else randomizeCard();
              }}
            >
              <Image
                src={randomImage}
                alt="randomize-button"
                style={{
                  width: "auto",
                  objectFit: "contain",
                }}
                width={15}
                height={12}
                loading="lazy"
              />
            </div>
            <div
              className="number-picker__picker--delete betErase linecard-icon d-flex align-items-center justify-content-center"
              title="Clear"
              onClick={() => {
                if (!fromCart) {
                  dispatchSetActiveLineCardValues({
                    index: lineNumber - 1,
                    values: [],
                    totalCombination,
                  });
                } else clearCard();
              }}
            >
              <Image
                src={clearImage || ""}
                alt="clear-button"
                style={{
                  width: "15px",
                  height: "15px",
                  objectFit: "contain",
                }}
                width={8}
                height={8}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="number-picker__numbers">{lines}</div>
      </div>
    </>
  );
}

LineCard.propTypes = {
  /**
   * Card title
   */
  cardTitle: PropTypes.string,
  /**
   * Line Number
   */
  lineNumber: PropTypes.number,
  /**
   * Line card values
   */
  selectedNumbers: PropTypes.array.isRequired,
  /**
   * Card active status
   */
  isActive: PropTypes.bool,
  /**
   * Disable line text
   */
  disableLine: PropTypes.string,
  /**
   * Randome image path
   */
  randomImage: PropTypes.string,
  /**
   * Clear image path
   */
  clearImage: PropTypes.string,
  /**
   * Total number combination
   */
  totalCombination: PropTypes.number,
  /**
   * Total digit number
   */
  totalLineDigits: PropTypes.number,
  /**
   * Activate card for edit cart
   */
  activateCard: PropTypes.func,
  /**
   * Radomize card for edit cart
   */
  randomizeCard: PropTypes.func,
  /**
   * Clear card for edit cart
   */
  clearCard: PropTypes.func,
  /**
   * Indicator is the Card is for cart
   */
  fromCart: PropTypes.bool,
};
